export const AREAS = {
    LONDON: {
      name: 'London',
      alias: 'london'
    },
    KEIGHLEY: {
      name: 'Keighley',
      alias: 'keighley'
    },
    BINGLEY: {
      name: 'Bingley',
      alias: 'bingley'
    },
    BRADFORD: {
      name: 'Bradford',
      alias: 'bradford'
    },
    OAKWORTH: {
      name: 'Oakworth',
      alias: 'oakworth'
    },
    OXENHOPE: {
      name: 'Oxenhope',
      alias: 'oxenhope'
    }
}

export const AREAS_NAMES_ALL = Object.keys(AREAS);

export const AREAS_NAMES   = ['WEST_YORKSHIRE', 'KEIGHLEY', 'BINGLEY', 'BRADFORD', 'OAKWORTH'];
export const AREAS_NAMES_1 = ['WEST_YORKSHIRE', 'KEIGHLEY', 'BINGLEY'];
export const AREAS_NAMES_2 = ['BRADFORD', 'OAKWORTH', 'OXENHOPE'];




// TODO: Get all the URLs/alias from Strapi and use it here
export const URLS = {
  LEA_RECRUITMENT: {
      name: 'London, UK Estate Agency Recruitment',
      alias: '/'
    },
    JOB_SEARCH: {
      name: 'London Property Job Search',
      alias: '/job-search'
    },
    ADVICE_HELP: {
        name: 'Advice on Estate Agent and PropTech',
        alias: '/help-and-advice',
        // name2: 'About Holroyds Estate Agents'
    },
    ABOUT: {
      name: 'About Crux Careers',
      alias: '/about'
    },
    CAREERS: { 
      name: 'Careers with Crux',
      alias: '/careers-with-crux'
    },
    CHECKLIST: {
      name: 'Employer Checklist',
      alias: '/help-and-advice/employer-guide/employer-checklist'
    },
    TESTIMONIALS: { 
      name: 'Our Testimonials',
      alias: '/about/reviews'
    },
    CANDIDATES: { 
      name: 'Candidates',
      alias: '/candidates'
    },
    UPLOAD_CV: { 
      name: 'Apply for PropTech and Estate Agent Jobs',
      alias: '/candidates/upload-your-cv'
    },
    INTERVIEW_PREPARATION : {
      name : 'Interview Preparation',
      alias: '/help-and-advice/candidate-guide/interview-preparation'
    },
    REFER: { 
      name: 'Refer a Candidate',
      alias: '/candidates/refer-a-candidate/'
    },
    TIPS: { 
      name: 'PropTech job Interview tips',
      alias: '/help-and-advice/candidate-guide/interview-preparation'
    },
    POST_JOBS: { 
      name: 'Post Estate Agents Jobs',
      alias: '/employers/post-jobs/'
    },
    REG_EMPLOYER: { 
      name: 'Register as Employer',
      alias: '/employers/register/'
    },
    REG_CANDIDATE: { 
      name: 'Job Offers and Negotiation',
      alias: '/help-and-advice/employer-guide/job-offers-and-negotiation'
    },
    SALES_NEGOTIATOR: { 
      name: 'Sales Negotiator Jobs in London',
      alias: '/job-search/search-Sales+Negotiator/'
    },
    LET_NEGOTIATOR: { 
      name: 'Lettings Negotiator Jobs in London',
      alias: '/job-search/search-Lettings+Negotiator//'
    },
    SMANAGER: { 
      name: 'Manager Jobs in London',
      alias: '/job-search/search-Manager/'
    },
    TRAINEE: { 
      name: 'Trainee Jobs in London',
      alias: '/job-search/search-Trainee/'
    },
    VALUER: { 
      name: 'Valuer Jobs in London',
      alias: '/job-search/search-Valuer/'
    },
    TRAINER: { 
      name: 'Trainer Jobs in London',
      alias: '/job-search/search-Trainer/'
    },
    AC: { 
      name: 'Account Manager Jobs in London',
      alias: '/job-search/search-Account+Manager/'
    },
    PM: { 
      name: 'Project Manager Jobs in London',
      alias: '/job-search/search-Project+Manager/'
    },
    MB: { 
      name: 'Mortgage Broker Jobs in London',
      alias: '/job-search/search-Mortgage+Broker/'
    },
    JOBDETAILS: { 
      name: 'Job Details',
      alias: '/job-details'
    },

}

// export const AREA_GUIDE_URLS = {
//   KEIGHLEY: {
//     name: URLS.AREA_GUIDES_KEIGHLEY.name,
//     alias: URLS.AREA_GUIDES_KEIGHLEY.alias
//   },
//   BINGLEY: {
//     name: URLS.AREA_GUIDES_BINGLEY.name,
//     alias: URLS.AREA_GUIDES_BINGLEY.alias
//   },
//   OXENHOPE: {
//     name: URLS.AREA_GUIDES_OXENHOPE.name,
//     alias: URLS.AREA_GUIDES_OXENHOPE.alias
//   }
// }
// export const AREA_GUIDE_AREAS = Object.keys(AREA_GUIDE_URLS);

// export const SEARCH_LOCATION = AREAS.WEST_YORKSHIRE.name; // Default Search Location
// export const DEFAULT_AREA = AREAS.WEST_YORKSHIRE;
export const LEA_RECRUITMENT_PAGE_URL = URLS.LEA_RECRUITMENT;
export const JOB_SEARCH_PAGE_URL = URLS.JOB_SEARCH;
export const ADVICE_HELP_PAGE_URL = URLS.ADVICE_HELP;
export const ABOUT_PAGE_URL = URLS.ABOUT;
export const CAREERS_PAGE_URL = URLS.CAREERS;
export const TESTIMONIALS_PAGE_URL = URLS.TESTIMONIALS;
export const UPLOAD_CV_PAGE_URL = URLS.UPLOAD_CV;
export const INTERVIEW_PREPARATION_PAGE_URL = URLS.INTERVIEW_PREPARATION;
export const REFER_PAGE_URL = URLS.REFER;
export const TIPS_PAGE_URL = URLS.TIPS;
export const POST_JOBS_PAGE_URL = URLS.POST_JOBS;
export const REG_EMPLOYER_PAGE_URL = URLS.REG_EMPLOYER;
export const REG_CANDIDATE_PAGE_URL = URLS.REG_CANDIDATE;
export const CHECKLIST_PAGE_URL = URLS.CHECKLIST;
export const SALES_NEGOTIATOR_PAGE_URL = URLS.SALES_NEGOTIATOR;
export const LET_NEGOTIATOR_PAGE_URL = URLS.LET_NEGOTIATOR;
export const SMANAGER_PAGE_URL = URLS.SMANAGER;
export const TRAINEE_PAGE_URL = URLS.TRAINEE;
export const VALUER_PAGE_URL = URLS.VALUER;
export const TRAINER_PAGE_URL = URLS.TRAINER;
export const AC_PAGE_URL = URLS.AC;
export const PM_PAGE_URL = URLS.PM;
export const MB_PAGE_URL = URLS.MB;

export const LONDON_NAME = AREAS.LONDON.name





