import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";
import PopularSearchesLinks from './PopularSearchesLinks';
import PopularSearchesHeader from './PopularSearchesHeader';
import { POPULAR_SEARCH_HEADING } from "./constants";
import  "./assets/styles/_index.scss";
gsap.registerPlugin(ScrollTrigger);
const PopularSearchAccordion = (props) => {

    const [activeKey, setActiveKey] = useState("");

    let contAnim = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            },
            scrollTrigger: {
                trigger: contAnim,
                start: 'top 70%',
                end: 'bottom bottom',
                toggleActions: 'play none none reverse',
                ease: animSetting.ease
            }
        });
        tl.to(contAnim, {
            opacity: 1,
            duration: 1.5
        })
    }, [])

    let _props = {...props};

    let _col1Links = _props.col1Links || [];
    // console.log(_col1Links)
    // Remove (if any) empty items
    _col1Links = _col1Links.filter(e => e);
    
    // let toggleClass = () => {
    //     setActive(!active);
    // }

    // useEffect(() => {
    //     let footerPopularOne = $('.footer_popular_one');
    //     let cardHeader = $('.card-header', footerPopularOne);
    //     let collapseShow = $('.collapse.show', footerPopularOne);
    //     if (collapseShow) {
    //         cardHeader.addClass('show');
    //     }
    // }, [active]);

    // if there is no links to show, then return
    if ( !(_col1Links ) ) {
        return null;
    }

    return (
        <section className={`section section-popular-searches section-grey-bg`} ref={el => contAnim = el}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <Accordion defaultActiveKey="" activeKey={activeKey}>
                            <Card className={`key-0`}>
                                <PopularSearchesHeader
                                    item={POPULAR_SEARCH_HEADING}
                                    activeKey={activeKey}
                                    setActiveKey={setActiveKey}
                                />
                                
                                <Accordion.Collapse eventKey={`key-0`}>
                                    <PopularSearchesLinks
                                        linksArray={_col1Links}
                                    />
                                </Accordion.Collapse>
                            </Card>
                        
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
            // <Row className="popular-search">
            //     <Col lg={12}>
            //         <div className="footer-divider-line"></div>

            //             <Accordion className='footer_popular_one' onClick={toggleClass}>

            //                 <Accordion.Toggle
            //                     as={Card.Header}
            //                     eventKey="0"
            //                     className={"text-uppercase" + (active ? ' active' : '')}>
            //                     {POPULAR_SEARCH_HEADING}
            //                 </Accordion.Toggle>

            //                 <Accordion.Collapse eventKey="0">
            //                     <Card.Body>
            //                         <Row>

            //                             {/* {Column 1} */}
            //                             <Col xl={3} md={6} className="mb-md-5 mb-0 column-div">
            //                                 <ul className="list-group list-group-flush">
            //                                     {_col1Links && _col1Links.map((url, index) => {
            //                                         return (
            //                                             <li className="list-group-item">
            //                                                 <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
            //                                             </li>
            //                                         )
            //                                     })}
            //                                 </ul>
            //                             </Col>

            //                             {/* {Column 2} */}
            //                             <Col xl={3} md={6} className="mb-md-5 mb-0 column-div">
            //                                 <ul className="list-group list-group-flush">
            //                                     {_col2Links && _col2Links.map((url) => {
            //                                         return (
            //                                             <li className="list-group-item">
            //                                                 <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
            //                                             </li>
            //                                         )
            //                                     })}
            //                                 </ul>
            //                             </Col>

            //                             {/* {Column 3} */}
            //                             <Col xl={3} md={6} className="mb-md-5 mb-0 column-div property-ipad">
            //                                 <ul className="list-group list-group-flush">
            //                                     {_col3Links && _col3Links.map((url) => {
            //                                         return (
            //                                             <li className="list-group-item">
            //                                                 <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
            //                                             </li>
            //                                         )
            //                                     })}
            //                                 </ul>
            //                             </Col>

            //                             {/* {Column 4} */}
            //                             <Col xl={3} md={6} className="mb-md-5 mb-0 column-div property-ipad">
            //                                 <ul className="list-group list-group-flush">
            //                                     {_col4Links && _col4Links.map((url) => {
            //                                         return (
            //                                             <li className="list-group-item">
            //                                                 <Link to={`${url.alias}`}>{getUrlName(url)}</Link>
            //                                             </li>
            //                                         )
            //                                     })}
            //                                 </ul>
            //                             </Col>
                                        
            //                         </Row>
            //                     </Card.Body>
            //                 </Accordion.Collapse>

            //             </Accordion>
            //         <div className="footer-divider-line"></div>
            //     </Col>
            // </Row>
    )
}

export default PopularSearchAccordion;